#imageLoader {
    width: 100%;
    height: 100%;
}

#dateSelector {
    margin-top: 2%;
}

#dateInputDiv {
    margin-bottom: 0px;
}

#dateSwitch {
    width: 100%;
}

.dateSwitchButton {
    width: 50%;
    padding: 0px;
    font-size: 25px;
    background-color: white;
}

.dateSwitchButton:hover {
    background-color: rgb(218, 218, 218);
}

.dateSwitchButton:active {
    background-color: rgb(173, 173, 173);
}

label {
    color: white;
}

.col-6 {
    padding-left: 5%;
    padding-right: 5%;
}

div.galleryContainer {
    margin-top: 5%;
    margin-bottom: 10%;
    padding: 3px;
    float: left;
    -webkit-transition: margin 0.5s ease-out;
    -moz-transition: margin 0.5s ease-out;
    -o-transition: margin 0.5s ease-out;
}

div.galleryContainer:hover {
    cursor: pointer;
    margin-top: 3%;
}

div.gallery {
    background-color: gray;
    /* Polaroid style */
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.589), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

div.gallery:hover {
    background-color: gray;
    /* Polaroid style */
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

div.gallery img {
    width: 100%;
    /* height: auto; */
    border-radius: 6px;
}

div.desc {
    padding: 15px;
    text-align: center;
    color: rgb(41, 41, 41);
}