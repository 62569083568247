#elementoSuperior {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 54, 143, 0.685);
  z-index: 9999;
  backdrop-filter: blur(10px);
  overflow-y: auto;
}

.modalContainer {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 15px;
}

.textRight {
  text-align: right;
  font-style: italic;
}

.textCenter {
  font-size: 28px;
  text-align: center;
}